import React from "react";
import { Label } from "./label";
import { Input } from "./input";

export const Privacy: React.FC = (props): JSX.Element => (
  <div className="flex items-center space-x-1">
    <Input id="privacy" name="privacy" type="checkbox"></Input>
    <Label>Accetta la nostra <a>privacy policy</a></Label>
  </div>
);
