import React, { LabelHTMLAttributes } from "react";

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  text?: string
}

export const Label: React.FC<LabelProps> = (props: LabelProps): JSX.Element => (
  <label className={props.className} id={props.id} htmlFor={props.htmlFor}>
    {props.children || props.text}
  </label>
);
