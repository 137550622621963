import React from "react";
import { navigate } from "gatsby";
import { Formik } from "formik";
import Axios from "axios";
import { BasicFormProps } from "./basic-form";
import SVG from "../svg";
import environment from "../../config/environment";
import { ValidationFormSchema } from "../../services/validation.service";

interface InlineFormProps extends BasicFormProps {}

export const InlineForm: React.FC<InlineFormProps> = (
  props: InlineFormProps
) => (
  <div className="flex flex-col space-y-2">
    <span className="text-sm font-">{props.title}</span>
    <Formik
      initialValues={{ EMAIL: "" }}
      validationSchema={ValidationFormSchema}
      onSubmit={(values, actions) => {
        Axios.post(
          `${environment.API.host}${environment.API.postMailchimpLeads.replace(
            ":listId",
            props.mailchimp?.audienceid
          )}`,
          {
            email_address: values["email"],
            status: "pending",
            merge_fields: { ...values },
            tags: props.mailchimp?.tags?.map(tag => tag.name),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) =>
            navigate("/grazie-per-esserti-iscritto-alla-beta")
          )
          .catch((err) => navigate("/grazie-per-esserti-iscritto-alla-beta/"));
      }}
    >
      {(propsFormik) => (
          <form
            className={`px-4 py-3 flex flex-col bg-white shadow rounded-lg ${propsFormik.errors.EMAIL ? `border-red-700 border`: ``}`}
            onSubmit={propsFormik.handleSubmit}
            onReset={propsFormik.handleReset}
          >
            <div className="flex flex-row items-center md:space-x-2">
              {React.Children.map(props.children, (child) => child)}
              <SVG
                className="w-3 h-3 text-gray-700 stroke-current"
                path="M17 8l4 4m0 0l-4 4m4-4H3"
              ></SVG>
            </div>
          </form>
      )}
    </Formik>
  </div>
);
