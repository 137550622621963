import React from "react";

export type ShortcodeStructure = {
  name: string;
  attributes: { [id: string]: string };
  children: any;
};

export default class ShortcodeParserService {
  static WPShortcode = /(\[.*].*\[\/.*\])/g;
  static WPShortcodeChildren = /(\[.*?(?!\[)\])(?=\[)/g;
  static WPShortcodeFields = /([A-z\s]+(?=\s|\]))|([A-z]+=('|’|"|”)[\s.’,'<>/\0-9A-zÀ-ÿ-]+('|’|"|”))/g;
  // static WPShortcodeFields2 = /(?<=\[)([A-z\s]+(?=\s|\]))|([A-z]+=('|’|"|”)[\s.’,'<>/\0-9A-zÀ-ÿ-]+('|’|"|”))/g;

  static extractWpShortcode(text: string) {
    return text.match(this.WPShortcode);
  }

  private static parseAttributes(attributes: Array<string>) {
    let attributesMap = {};

    attributes.forEach((attr) => {
      attributesMap = {
        [attr.split("=")[0]]: attr.split("=")[1].replace(/'|’|"|”/gi, ""),
        ...attributesMap,
      };
    });

    return attributesMap;
  }

  static parse(shortCode: string): ShortcodeStructure {
    const [parent, ...children] = shortCode.match(
      this.WPShortcodeChildren
    ) as Array<any>;

    console.log(parent);

    const [componentName, ...attributes] = parent.replace(/\[/g, '').match(
      this.WPShortcodeFields
    ) as Array<string>;

    console.log(componentName);

    return {
      name: componentName,
      attributes: attributes[0] ? this.parseAttributes(attributes) : {},
      children: children[0]
        ? children.map((child) => {
            if (!child) {
              return;
            } else {
              const [componentName, ...attributes] = child.match(
                this.WPShortcodeFields
              ) as Array<string>;

              return {
                name: componentName,
                attributes: this.parseAttributes(attributes),
              };
            }
          })
        : undefined,
    };
  }

  static createComponent(
    shortcodeParsed: ShortcodeStructure,
    componentsMap: { [componentName: string]: any }
  ) {
    let children = shortcodeParsed.children?.map((child) =>
      React.createElement(componentsMap[child.name], child.attributes)
    );

    if (!shortcodeParsed) return;
    if (!componentsMap[shortcodeParsed.name]) return;
    
    return React.createElement(componentsMap[shortcodeParsed.name], {
      children,
      ...shortcodeParsed.attributes,
    });
  }
}
