import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import HTMLReactParser from "html-react-parser";

import ShortcodeParserService from "../services/shortcode-parser-service";
import leadsFormsMap from "../config/leads-forms-map.tsx";
import ComponentsMap from "../config/components-map.tsx";
import { FormWrapper } from "../components/leads-components/form-wrapper";
import Layout from "../components/layout";
import EbookPreview from "../components/ebook/ebook-preview";
import HTMLParser from "../components/html-parser";

export default ({ data, pageContext: { id, title } }) => {
  const [parsedContent, setParsedContent] = useState();
  const componentsMap = { ...ComponentsMap, ...leadsFormsMap };

  useEffect(() => {
    setParsedContent(
      HTMLReactParser(data.ebook.content, {
        replace: (node) => {
          if (node.data) {
            const shortcodes = ShortcodeParserService.extractWpShortcode(
              node.data
            );

            if (shortcodes) {
              const shortcodeParsed = ShortcodeParserService.parse(
                shortcodes[0]
              );

              if (shortcodeParsed.name === "FormWrapper") {
                return (
                  <FormWrapper {...shortcodeParsed.attributes}></FormWrapper>
                );
              } else {
                return ShortcodeParserService.createComponent(
                  shortcodeParsed,
                  componentsMap
                );
              }
            }
          }
        },
      })
    );
  }, []);

  return (
    <Layout seo={data.ebook.seo}>
      <article className="py-32 md:pl-5 w-full flex flex-col md:flex-row items-start justify-between">
        <EbookPreview
          className="pt-20 overflow-y-auto md:sticky max-h-screen top-2"
          title={data.ebook.featuredImage.node.title}
          sourceUrl={data.ebook.featuredImage.node.sourceUrl}
        ></EbookPreview>
        <div clasName="flex flex-none flex-col overflow-y-auto">
          <div class="">
            <h1 className="mb-12">{data.ebook.title}</h1>
            <span className="prose lg:prose">{!parsedContent ? <HTMLParser>{ data.ebook.content }</HTMLParser>: parsedContent }</span>
          </div>
        </div>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query ebook($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    ebook: wpEbook(id: { eq: $id }) {
      id
      uri
      title
      content
      date
      featuredImage {
        node {
          id
          sourceUrl
          title
          description
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
      }
    }
  }
`;
