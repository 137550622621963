import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Button } from "./button";
import { Input } from "./input";
import leadsFormsMap from "../../config/leads-forms-map";
import PrivacyPolicyCheckbox from "./privacy-policy-checkbox";

type FormInputProps = {
  id?: number;
  children?: any;
};

export const FormWrapper: React.FC<FormInputProps> = (
  props: FormInputProps
): JSX.Element => {
  const {
    allWpForm: { edges: Forms },
  } = useStaticQuery(graphql`
    query {
      allWpForm {
        edges {
          node {
            id
            title
            databaseId
            Mailchimp {
              audienceid {
                id
                slug
                name
              }
              tags {
                name
              }
              events {
                name
              }
            }
            UIComponentNameSelector {
              uiComponentNameSelector {
                id
                name
              }
            }
            FormInput {
              formheader {
                title
                subtitle
              }
              formfields {
                id
                classname
                type
                label
                placeholder
                name
              }
              formfooter {
                button {
                  text
                  classname
                }
                redirect {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const [{ node: selectedForm }, ...rest] = Forms.filter(
    ({ node }) => node.databaseId.toString() === props.id
  );

  //console.log(selectedForm);

  if (selectedForm) {
    return React.createElement(
      leadsFormsMap[
        selectedForm.UIComponentNameSelector.uiComponentNameSelector.name
      ],
      {
        id: selectedForm.databaseId,
        ...selectedForm.FormInput.formheader,
        ...selectedForm.FormInput.formfooter,
        mailchimp: { ...selectedForm.Mailchimp },
      },
      [
        selectedForm.FormInput.formfields.map((field) => (
          <Input {...field}></Input>
        )),
        <PrivacyPolicyCheckbox></PrivacyPolicyCheckbox>,
        !selectedForm.FormInput.formfooter.button ? ( 
          <Button
            type="submit"
            dataLayerEvent={`${selectedForm.id} - ${selectedForm.title}`}
            className={`${selectedForm.FormInput.formfooter.button.classname}`}
            text={selectedForm.FormInput.formfooter.button.text}
          ></Button>
        ) : (
          <Button
            type="submit"
            dataLayerEvent={`${selectedForm.id} - ${selectedForm.title}`}
            className={`${selectedForm.FormInput.formfooter.button.classname}`}
            text={selectedForm.FormInput.formfooter.button.text}
          ></Button>
        ),
        <span className="text-xs text-gray-400">
          This site is protected by reCAPTCHA and the Google{" "}
          <a className="opacity-50" href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
          <a className="opacity-50" href="https://policies.google.com/terms">Terms of Service</a>{" "}
          apply.
        </span>,
      ]
    );
  }

  return (
    <span className="p-4 border-4 border-dotted border-red-700 rounded-lg text-red-700 text-lg font-semibold">
      Errore durante il caricamente del componente Form con id{" "}
      <span className="px-1 bg-red-700 text-white">{props.id}</span>,
      controllare la correttezza dell'id
    </span>
  );
};
