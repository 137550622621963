import { AccessBetaForm } from "../components/leads-components/access-beta-form";
import { BasicForm } from "../components/leads-components/basic-form";
import { Button } from "../components/leads-components/button";
import { Div } from "../components/leads-components/div";
import { Input } from "../components/leads-components/input";
import { Label } from "../components/leads-components/label";
import { Privacy } from "../components/leads-components/privacy";
import { InlineForm } from "../components/leads-components/inline-form";
// import { FormWrapper } from "../components/leads-components/form-wrapper";

export default {
  AccessBetaForm,
  InlineForm,
  BasicForm,
  Div,
  Label,
  Input,
  Button,
  Privacy,
  // FormWrapper ,
};
