import React, { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  dataLayerEvent?: string;
}

export const Button: React.FC<ButtonProps> = (
  props: ButtonProps
): JSX.Element => {
  return (
    <button
      type={props.type}
      className={`focus:outline-none mt-2 px-3 py-2 text-lg rounded font-medium bg-gray-200
      ${
        !props.className
          ? `mt-2 px-3 py-2 text- rounded bg-gray-200 font-medium`
          : props.className
      }`}
      id={props.id}
      name={props.name}
      disabled={props.disabled}
      onClick={(event) => {
        // window.dataLayer?.push({
        //   event: props.dataLayerEvent || props.text,
        // });

        if (props.onClick) {
          props.onClick(event);
        }
      }}
    >
      {props.children || props.text}
    </button>
  );
};
