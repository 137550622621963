import React from "react";

type EbookPreviewProps = {
  id: string;
  sourceUrl: string;
  title: string;
  className?: string;
};

const EbookPreview: React.FC<EbookPreviewProps> = (
  props: EbookPreviewProps
): JSX.Element => <img className={`${props.className} md:w-full`} src={props.sourceUrl} title={props.title}></img>;

export default EbookPreview;
