import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import IubendaCookieConsent from "../components/iubenda-cookie-consent";
import SEO, { SEOProps } from "../components/SEO/seo";
import { Popover } from "@headlessui/react";

type PropsLayout = {
  seo?: SEOProps;
};
const Layout: React.FC<PropsLayout> = (props: PropsLayout): JSX.Element => (
  <Popover className="relative overflow-hidden">
    {({ open }) => (
      <>
        <SEO {...props.seo}></SEO>
        <div className="p-3 md:p-0  w-full">
          <IubendaCookieConsent></IubendaCookieConsent>
          <div className="pb-10 container mx-auto">
            <Navbar open={open} />
            {props.children}
          </div>
          <Footer />
        </div>
      </>
    )}
  </Popover>
);

export default Layout;
