import React from "react";
import { navigate } from "gatsby";
import { Formik } from "formik";

export interface AccessBetaFormProps  {
  formId?: string;
  formName?: string;
  formAction?: string;
  listId?: string;
  title?: string;
  subtitle?: string;
  features?: string[];
  cta?: string;
  onSubmit?: any;
};

export const AccessBetaForm: React.FC<AccessBetaFormProps> = (
  props: AccessBetaFormProps
): JSX.Element => (
  <div className="flex flex-col md:flex-row justify-center bg-white rounded shadow">
    <div className="p-8 md:p-10 flex flex-col md:border-r">
      <span className="text-3xl font-semibold">{props.title}</span>
      <p className="mt-3 text-xs text-gray-700">{props.subtitle}</p>
      <ul className="mt-6">
        {props.features?.map((feature) => (
          <li className="flex items-center space-x-1 text-xs">
            <svg
              className="w-4 h-4 text-green-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="text-gray-700">{feature}</span>
          </li>
        ))}
      </ul>
    </div>
    <div className="p-8 pt-0 md:p-10 w-full h-full flex items-center">
      <Formik
        initialValues={{ FNAME: "Davide", EMAIL: "thekage91@gmail.com" }}
        onSubmit={(values, actions) => {
          fetch(
            "",
            {
              method: "POST",
              body: JSON.stringify(values),
              redirect: "follow",
              headers: {
                "Content-Type": "application/json; charset=utf-8",
              },
            }
          ).then(
            (response) => navigate("/thank-you"),
            (err) => console.log(err)
          );
        }}
      >
        {(propsFormik) => (
          <form
            action={props.formAction || `https://thezyro.us11.list-manage.com/subscribe/post?u=baa6a96ac00514e2d994c55e2&amp;id=${props.listId || "fddacd8541"}`}
            method="post"
            id={props.formId || "mc-embedded-subscribe-form"}
            name={props.formName || "mc-embedded-subscribe-form"}
            target="_blank"
            className="flex flex-col flex-grow space-y-2 validate"
            noValidate
            //onSubmit={propsFormik.handleSubmit}
            //onReset={propsFormik.handleReset}
          >
            <div className="flex flex-col space-y-1">
              <label className="text-sm" htmlFor="name">
                {" "}
                Name{" "}
              </label>
              <input
                type="text"
                id="FNAME"
                name="FNAME"
                className="px-2 py-1 border rounded bg-white"
                value={propsFormik.values.FNAME}
                onChange={propsFormik.handleChange}
                onBlur={propsFormik.handleBlur}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="text-sm" htmlFor="email">
                {" "}
                Email{" "}
              </label>
              <input
                type="email"
                id="EMAIL"
                name="EMAIL"
                className="px-2 py-1 border rounded bg-white"
                value={propsFormik.values.EMAIL}
                onChange={propsFormik.handleChange}
                onBlur={propsFormik.handleBlur}
              />
            </div>
            <button
              type="submit"
              className="mt-2 px-3 py-2 text-sm rounded bg-gray-200"
            >
              {props.cta}
            </button>
          </form>
        )}
      </Formik>
    </div>
  </div>
);